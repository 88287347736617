/* From Google Developer Student Clubs - Chapter Danang University of Science and Technology with love <3 */
:root {
  --color-red: #ea4335;
  --color-blue: #4285f4;
  --color-yellow: #fbbc05;
  --color-green: #34a853;
  --timing-animation: 3s;
}
.loader {
  position: relative;
  width: 225px;
  height: 150px;
}
.dot {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 9999px;
}
.dot1 {
  top: 55px;
  background-color: var(--color-blue);
  transform-origin: 20px center;
  animation: dsc-dot1 var(--timing-animation) infinite ease-in-out;
  z-index: 2;
}
.dot2 {
  left: 62.5px;
  top: 55px;
  background-color: var(--color-red);
  animation: dsc-dot2 var(--timing-animation) infinite ease-in-out;
}
.dot3 {
  bottom: 0;
  left: 62.5px;
  background-color: var(--color-green);
  animation: dsc-dot3 var(--timing-animation) infinite ease-in-out;
}
.dot4 {
  left: 62.5px;
  background-color: var(--color-green);
  animation: dsc-dot4 var(--timing-animation) infinite ease-in-out;
}
.dot5 {
  left: 62.5px;
  top: 55px;
  background-color: var(--color-green);
  animation: dsc-dot5 var(--timing-animation) infinite ease-in-out;
}
.dot6 {
  left: 62.5px;
  bottom: 0;
  background-color: var(--color-yellow);
  animation: dsc-dot6 var(--timing-animation) infinite ease-in-out;
}
.dot7 {
  top: 55px;
  background-color: var(--color-red);
  transform-origin: 20px center;
  animation: dsc-dot7 var(--timing-animation) infinite ease-in-out;
  z-index: 1;
}
@keyframes dsc-dot1 {
  0%,
  100% {
    opacity: 0;
    transform: scale(0);
  }
  5%,
  95% {
    opacity: 1;
    transform: scale(1);
  }
  25%,
  75% {
    width: 40px;
  }
  30%,
  70% {
    width: 100px;
    transform: rotate(0);
  }
  35%,
  65% {
    transform: rotate(-30deg);
  }
  45%,
  55% {
    transform: rotate(30deg);
  }
  50% {
    width: 100px;
    transform: rotate(30deg);
  }
}
@keyframes dsc-dot2 {
  0%,
  100% {
    opacity: 0;
    transform: scale(0);
  }
  5%,
  95% {
    opacity: 0;
    transform: scale(0);
  }
  10%,
  90% {
    opacity: 1;
    width: 40px;
    transform: scale(1);
  }
  15%,
  85% {
    left: 62.5px;
  }
  20%,
  80% {
    width: 100px;
    left: 125px;
    transform: scale(1);
    transform-origin: 80px center;
  }
  25%,
  75% {
    transform: scale(0);
  }
  30%,
  70% {
    transform: scale(0);
    background-color: var(--color-red);
  }
  36%,
  64% {
    background-color: var(--color-yellow);
  }
  40%,
  60% {
    width: 100px;
    left: 125px;
    transform: scale(1);
    transform: rotate(-30deg);
  }
  50% {
    width: 100px;
    left: 125px;
    background-color: var(--color-yellow);
    transform: scale(1);
    transform: rotate(-30deg);
    transform-origin: 80px center;
  }
}
@keyframes dsc-dot3 {
  0%,
  100% {
    opacity: 0;
    transform: scale(0);
  }
  10%,
  90% {
    opacity: 0;
    transform: scale(0);
  }
  15%,
  85% {
    opacity: 1;
    transform: scale(1);
    width: 40px;
  }
  20%,
  80% {
    width: 100px;
    left: 62.5px;
  }
  25%,
  75% {
    width: 40px;
    left: 122.5px;
    transform: scale(1);
  }
  30%,
  70% {
    transform: scale(0);
  }
  50% {
    transform: scale(0);
  }
}
@keyframes dsc-dot4 {
  0%,
  100% {
    opacity: 0;
    transform: scale(0);
  }
  15%,
  85% {
    opacity: 0;
    transform: scale(0);
  }
  20%,
  80% {
    opacity: 1;
    transform: scale(1);
    width: 40px;
  }
  25%,
  75% {
    width: 100px;
    left: 62.5px;
  }
  30%,
  70% {
    width: 40px;
    left: 122.5px;
    transform: scale(1);
  }
  35%,
  65% {
    transform: scale(0);
  }
  50% {
    transform: scale(0);
  }
}
@keyframes dsc-dot5 {
  0%,
  100% {
    opacity: 0;
    transform: scale(0);
    transform-origin: left center;
  }
  10%,
  90% {
    opacity: 0;
    transform: scale(0);
  }
  15%,
  85% {
    opacity: 1;
    transform: scale(1);
    width: 40px;
    left: 62.5px;
    transform-origin: left center;
  }
  20%,
  80% {
    width: 100px;
    left: 125px;
    transform-origin: 80px center;
  }
  30%,
  70% {
    transform: rotate(0);
  }
  35%,
  65% {
    transform: rotate(-30deg);
  }
  45%,
  55% {
    transform: rotate(30deg);
  }
  50% {
    opacity: 1;
    transform: scale(1);
    transform: rotate(30deg);
    transform-origin: 80px center;
    left: 125px;
    width: 100px;
  }
}
@keyframes dsc-dot6 {
  0%,
  100% {
    opacity: 0;
    transform: scale(0);
  }
  15%,
  85% {
    opacity: 0;
    transform: scale(0);
  }
  20%,
  80% {
    opacity: 1;
    transform: scale(1);
    width: 40px;
  }
  22.5%,
  77.5% {
    width: 100px;
    left: 62.5px;
  }
  27.5%,
  72.5% {
    width: 40px;
    left: 122.5px;
    transform: scale(1);
  }
  32.5%,
  67.5% {
    transform: scale(0);
  }
  50% {
    transform: scale(0);
  }
}
@keyframes dsc-dot7 {
  0%,
  100% {
    opacity: 0;
    transform: scale(0);
  }
  35%,
  65% {
    opacity: 1;
    transform: scale(0);
  }
  36%,
  64% {
    transform: scale(1);
    width: 100px;
    transform: rotate(-30deg);
  }
  50% {
    transform: scale(1);
    width: 100px;
    transform: rotate(-30deg);
  }
}
