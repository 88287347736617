@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./assets/fonts/Poppins-Regular.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: 'Poppins', 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

::-moz-selection { /* Code for Firefox */
  color: black;
  background: #ffdf80;
}

::selection {
  color: black;
  background: #ffdf80;
}